<template>
  <div class="wrap">
    <b-nav vertical v-if="isSigned" class="mt-3">
      <b-nav-item to="/livematch" exact-active-class="active">
        <b-icon-play-btn class="icon" />Live Match
      </b-nav-item>
      <b-nav-item to="/Statistics" exact-active-class="active">
        <b-icon-bar-chart class="icon" />Telemetry
      </b-nav-item>
      <b-nav-item v-if="role === 'admin'" to="/teamUpdate" exact-active-class="active">
        <b-icon-slack class="icon" />TeamUpdate
      </b-nav-item>
      <b-nav-item to="/merge" exact-active-class="active">
        <b-icon-person-lines-fill class="icon" />Merge Player
      </b-nav-item>
      <b-nav-item to="/AdditionalPoint" exact-active-class="active">
        <b-icon-dash-circle class="icon" />Additional pts
      </b-nav-item>
      <b-nav-item to="/subscribe" exact-active-class="active">
        <b-icon-hdd-network class="icon" />Series Management
      </b-nav-item>
      <b-nav-item to="/customtournaments" exact-active-class="active">
        <b-icon-diagram2 class="icon" />Custom Tournaments
      </b-nav-item>
      <b-nav-item to="/manageMatch" exact-active-class="active">
        <b-icon-hdd-network class="icon" />Matches Management
      </b-nav-item>
      <b-nav-item to="/IgnoreMatch" exact-active-class="active">
        <b-icon-x-octagon class="icon" />Ignore Match
      </b-nav-item>
      <b-nav-item to="/downloadPage" exact-active-class="active">
        <b-icon-cloud-download class="icon" />Download
      </b-nav-item>
    </b-nav>
  </div>
</template>
<script>

import moment from 'moment'
export default {
  name: 'AsideFragment',
  components: {},
  mounted () {
    if (
      this.$store.state.isSigned === true &&
      moment().unix() - this.$store.state.userSession < 1800
    ) {
      console.log('yes')
      this.$store.commit('setUserSession', moment().unix())
      this.userName = this.$store.state.userName
      this.isLogin = true
    } else {
      console.log('no ')
      this.$store.commit('setIsSigned', false)
      this.$store.commit('setUserSession', 0)
      this.$store.commit('setUserName', 'Sign in')
      this.isLogin = false
      this.userName = 'Sign in'
    }
  },
  data () {
    return {
    }
  },
  computed: {
    isSigned () {
      return this.$store.state.isSigned
    },
    role () {
      return this.$store.state.role
    }
  },
  methods: {
  }
}
</script>
<style scoped>
.nav-link {
  color: #fff;
}

.nav-link:hover {
  color: #f29f01 !important;
}

.nav-link.active {
  color: #f29f01 !important;
}

.icon {
  padding-top: 3px;
  margin: 5px 20px 0px 10px;
}

.nav-item {
  text-align: left;
}
</style>
