<template>
  <div>
    <!-- <b-button @click="getRecentMatch">Recent Match</b-button>
    <b-input v-model="aMatch"></b-input> -->
    <b-input-group size="sm">
      <b-input-group-prepend>
        <b-button variant="outline-dark" @click="getRecentMatch">
          Recent Match
        </b-button>
      </b-input-group-prepend>
      <b-form-input v-model="aMatch"></b-form-input>
    </b-input-group>
    <vue-tabs @tab-change="handleTabChange" class="mt-3">
      <v-tab title="TEAM">
        <MyTable
          :columns="teamStatColumns"
          :tableDatas="teamStatMatchData"
          :isKillLog="false"
        />
      </v-tab>
      <v-tab title="PLAYER">
        <MyTable
          :columns="playerStatColumns"
          :tableDatas="playerStatMatchData"
          :isKillLog="false"
        />
      </v-tab>
      <v-tab title="KILLLOG">
        <MyTable
          :columns="killLogColumns"
          :tableDatas="killLogData"
          :isKillLog="true"
        />
      </v-tab>
    </vue-tabs>
  </div>
</template>

<script>
import MyTable from '@/components/customTable'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'
import axios from 'axios'
export default {
  components: {
    VueTabs,
    VTab,
    MyTable
  },
  data () {
    return {
      teamStatColumns: [
        {
          key: 'Team',
          label: 'Team',
          sortable: true
        },
        {
          key: 'Total_Kills',
          label: 'Total Kills',
          sortable: true
        },
        {
          key: 'Total_Death',
          label: 'Total Death',
          sortable: true
        },
        {
          key: 'eliminatedAt',
          label: 'eliminated Time',
          sortable: true
        }
      ],
      playerStatColumns: [
        {
          key: 'Player_ID',
          label: 'Player ID',
          sortable: true
        },
        {
          key: 'TeamName',
          label: 'Team Name',
          sortable: true
        },
        {
          key: 'TotalKill',
          label: 'Total Kill',
          sortable: true
        },
        {
          key: 'Revives',
          sortable: true
        },
        {
          key: 'Total_Damage',
          label: 'Total Damage',
          sortable: true
        }
      ],
      killLogColumns: [
        'index',
        { key: 'time_str', sortable: true },
        { key: 'Killer', sortable: true },
        { key: 'Victim', sortable: true }
      ],
      teamStatMatchData: [],
      playerStatMatchData: [],
      killLogData: [],
      aMatch: '',
      selectedMatchId: '',
      polling: null,
      timerState: 'uninit',
      tabIndex: 0,
      isDataOk: false
    }
  },
  computed: {
    selectedTournamentId () {
      return this.$store.state.selectedTournamentId
    },
    userName () {
      return this.$store.state.userName
    }
  },
  mounted () {
    // this.handleTabChange(0, null, null)
    this.$store.commit('showSeries', true)
    this.$store.commit('showTournaments', true)
    this.$store.commit('showMatches', false)
    this.$store.commit('refreshTeamRoster', false)
  },
  watch: {
    '$store.state.selectedTournamentId': {
      immediate: true,
      handler () {
        this.fetchDataFromVuex()
      }
    },
    aMatch () {
      this.changeMatch()
    }
  },
  methods: {
    changeMatch () {
      if (this.tabIndex === 1) {
        if (this.getMatchPlayerStats()) {
          this.stopPollData()
          this.pollData()
        }
      } else if (this.tabIndex === 0) {
        if (this.getMatchTeamStats()) {
          this.stopPollData()
          this.pollData()
        }
      }
      // else if (this.tabIndex === 2) {
      //   if (this.getMatchKillLog() === true) {
      //     this.stopPollData()
      //     this.pollData()
      //   }
      // }
    },
    windowOnBlur () {
      this.pauseTimer()
    },
    windowOnFocus () {
      console.log(this.timerState + 'ddd')
      if (this.timerState === 'paused') {
        this.startTimer()
      }
    },
    startTimer () {
      this.timerState = 'started'
    },
    pauseTimer () {
      this.timerState = 'paused'
    },
    stopTimer () {
      this.timerState = 'stopped'
    },
    pollingController () {
      if (this.timerState === 'started') {
        if (this.tabIndex === 1) {
          if (!this.getMatchPlayerStats()) {
            this.stopPollData()
          }
        } else if (this.tabIndex === 0) {
          if (!this.getMatchTeamStats()) {
            this.stopPollData()
          }
        }
        // else if (this.tabIndex === 2) {
        //   this.getMatchKillLog()
        // }
      }
    },
    fetchDataFromVuex () {
      this.tournamentid = this.$store.state.selectedTournamentId
      this.selectedSeries = this.$store.state.selectedSeries
    },
    setRecentMatch (res) {
      if (res.level != 'error') {
        console.log(res.id)
        this.aMatch = res.id
      }
    },
    getRecentMatch () {
      const api = `/api/pubg/RecentMatchInfo/${this.userName}/${this.selectedTournamentId}`
      axios
        .get(api)
        .then(response => {
          this.setRecentMatch(response.data.data.data)
        })
        .catch(ex => {
          console.warn(`Request Fail: ${api}`, ex)
        })
    },
    getMatchTeamStats () {
      if (this.aMatch === '') return false
      // api/pubg/liveleaderboard/PCS5-AS?tournament_id=as-pcs5w1&match_id=match.bro.custom.es_as-pcs5w1_02.steam.normal.krjp.2021.09.19.13.fef410b1-a9c8-4310-bd0e-aec10b2c97e8
      // var api = 'api/pubg/liveleaderboard/' + this.$store.state.selectedSeries + '?match_id=' + this.aMatch
      // var api = 'api/pubg/scoreboard/' + this.$store.state.selectedSeries + '?match_id=' + this.aMatc
      var api =
        'api/pubg/scoreboard3?account_id=' +
        this.$store.state.userName +
        '&series=' +
        this.$store.state.selectedSeries +
        '&match_id=' +
        this.aMatch
      // api/pubg/liveevent/test-pgcsc/recent
      axios
        .get(api)
        .then(response => {
          console.log('teamStats:' + api)
          if (response.data.data.teams === undefined) {
            this.isDataOk = false
          } else {
            this.isDataOk = this.parseStatistics(response.data.data, this.teamStatMatchData)
            console.log(this.teamStatMatchData)
            // this.isDataOk = true
          }
        })
        .catch(ex => {
          console.warn('error' + ex)
          this.stopPollData()
          this.isDataOk = false
        })
      return this.isDataOk
    },
    // getMatchKillLog () {
    //   // api/playerstatsbymatch/{series}?match_id={match_id}
    //   if (this.aMatch === undefined)
    //     this.aMatch = 'recent'
    //   var api = 'api/pubg/liveevent/' + this.$store.state.selectedTournamentId + '/' +this.aMatch
    //   axios.get(api).then(response => {
    //     console.log('match playerstats: ' + api)
    //     if (response.data.data.players.length < 1) {
    //       this.stopPollData()
    //       this.isDataOk = false
    //     } else {
    //       this.parseKillLog(response.data.data, this.killLogData)
    //       this.isDataOk = true
    //     }
    //   }).catch(ex => {
    //     console.warn('GameStatistics getMatchPlayerstats', ex)
    //     this.stopPollData()
    //     this.isDataOk = false
    //   })
    //   return this.isDataOk
    // },
    getMatchPlayerStats () {
      // api/playerstatsbymatch/{series}?match_id={match_id}
      // var api = 'api/pubg/playerstats/' + this.$store.state.selectedSeries + '?match_id=' + this.aMatch + '&live=true'
      if (this.aMatch === undefined) {
        this.aMatch = 'recent'
      }
      var api =
      `api/pubg/liveevent/${this.userName}/${this.$store.state.selectedTournamentId}/${this.aMatch}`
      axios
        .get(api)
        .then(response => {
          console.log('match playerstats: ' + api)
          if (response.data.data.players.length < 1) {
            this.stopPollData()
            this.isDataOk = false
          } else {
            this.isDataOk = this.parseStatisticsPlayer(
              response.data.data,
              this.playerStatMatchData
            )
            this.parseKillLog(response.data.data, this.killLogData)
            // this.isDataOk = true
          }
        })
        .catch(ex => {
          console.warn('GameStatistics getMatchPlayerstats', ex)
          this.stopPollData()
          this.isDataOk = false
        })
      return this.isDataOk
    },
    ResetTeamStat () {},
    handleTabChange (tabIndex) {
      this.tabIndex = tabIndex
      this.stopPollData()
      if (tabIndex === 1) {
        if (this.getMatchPlayerStats()) {
          this.stopPollData()
          this.pollData()
        }
      } else if (tabIndex === 0) {
        if (this.getMatchTeamStats()) {
          this.stopPollData()
          this.pollData()
        }
      }
      // else if (tabIndex === 2) {
      //   if (this.getMatchKillLog() === true) {
      //     this.stopPollData()
      //     this.pollData()
      //   }
      // }
    },
    parseKillLog (data, viewData) {
      viewData.splice(0)
      for (const key in data.players) {
        var aPlayer = data.players[key]
        if (aPlayer.isAlive === false) {
          var killLog = {
            // time: aPlayer.eliminatedAt,
            time: aPlayer.deathAt,
            time_str: aPlayer.deathAt,
            // time_srt: moment.unix(aPlayer.deathAt).format('HH:mm:ss'),
            Killer: aPlayer.killedBy,
            Victim: aPlayer.name
          }
          viewData.push(killLog)
        }
      }
      viewData.sort(function (a, b) {
        return b.time - a.time
      })
    },
    parseStatistics (data, viewData) {
      viewData.splice(0)
      //viewData = []
      for (const key in data.teams) {
        var aTeam = data.teams[key]
        var deathCount = 0
        for (const playerKey in aTeam.players) {
          if (!aTeam.players[playerKey].isAlive) {
            deathCount++
          }
        }
        const eliminatedAt = aTeam.elimination ? aTeam.eliminatedAtStr : ''
        var teamStats = {
          // Rank: aPlayer.teamStat.rank_total,
          Team: aTeam.team_tag,
          // Placement_Point: aPlayer.teamStat.place_pts,
          Total_Kills: aTeam.kills,
          // Total_Point: aPlayer.teamStat.total_pts,
          Total_Death: deathCount,
          eliminatedAt: eliminatedAt,
          status: !aTeam.elimination
        }
        viewData.push(teamStats)
      }
      console.log(viewData)
      // 타이머 종료 게임 끝인지 보고
      if (data.endedAt === undefined || data.endedAt === '') {
        return true
      } else {
        console.log(data.endedAt)
        return false
      }
    },
    parseStatisticsPlayer (data, viewData) {
      // this.playerStatData.splice(0)
      viewData.splice(0)
      //viewData = []
      for (const key in data.players) {
        var aPlayer = data.players[key]
        const teamName = aPlayer.name.split('_')
        var playerStats = {
          Player_ID: aPlayer.name,
          TeamName: teamName[0],
          TotalKill: aPlayer.kills,
          Revives: aPlayer.revives,
          Total_Damage: aPlayer.damageDealt.toFixed(0),
          status: aPlayer.isAlive
        }
        viewData.push(playerStats)
      }
      // 타이머 종료 게임 끝인지 보고
      if (data.endedAt === undefined || data.endedAt === '') {
        return true
      } else {
        console.log(data.endedAt)
        return false
      }
    },
    pollData () {
      window.addEventListener('focus', this.windowOnFocus)
      window.addEventListener('blur', this.windowOnBlur)
      this.startTimer()
      this.polling = setInterval(() => {
        // this.$store.dispatch('RETRIEVE_DATA_FROM_BACKEND')
        // this.getPlayerHeartrate(this.seriesName)
        this.pollingController()
      }, 1000)
    },
    stopPollData () {
      this.stopTimer()
      window.removeEventListener('focus', this.windowOnFocus)
      window.removeEventListener('blur', this.windowOnBlur)
      clearInterval(this.polling)
    }
  },
  beforeDestroy () {
    this.stopTimer()
    window.removeEventListener('focus', this.windowOnFocus)
    window.removeEventListener('blur', this.windowOnBlur)
    clearInterval(this.polling)
  }
}
</script>

<style></style>
