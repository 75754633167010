<template>
  <div v-if="role === 'admin'">
    <loading :active.sync="isLoading" :is-full-page="false"></loading>
    <MyGrid
      :columns="playerColumns"
      :tableDatas="teams"
      />
  </div>
</template>

<script>
// esline-disable-next-line
// import Constant from '@/constant'

import axios from 'axios'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
// import MyGrid2 from '@/components/customTable2'
import MyGrid from '@/components/customTable'
const emptyPlayer = {
  team_id: 0,
  team_name: '',
  team_tag: '',
  player_id: 0,
  player_name: '',
  player_mapping: ''
}

export default {
  components: {
    Loading,
    MyGrid
  },
  mounted () {
    this.$store.commit('showSeries', true)
    this.$store.commit('showTournaments', false)
    this.$store.commit('showMatches', false)
    this.$store.commit('refreshTeamRoster', true)
    // this.getPlayer()
    this.setTeamRoster()
  },
  data () {
    return {
      teams: [],
      playerColumns: [],
      rosterName: '',
      // columns: Constant.PLAYER_COLUMN,
      player: emptyPlayer,
      isEditMode: false,
      teamIdState: null,
      teamNameState: null,
      teamTagstate: null,
      playerIdState: null,
      playerNameState: null,
      isLoading: false
    }
  },
  computed: {
    series () {
      return this.$store.state.series
    },
    selectedSeries () {
      return this.$store.state.selectedSeries
    },
    teamRoster () {
      return this.$store.state.teamRoster
    },
    role () {
      return this.$store.state.role
    }
  },
  watch: {
    selectedSeries () {
      // this.getPlayer()
      this.setTeamRoster()
    },
    teamRoster () {
      // this.getPlayer()
      this.setTeamRoster()
    }
  },
  methods: {
    setTeamRoster () {
      this.teams.splice(0)

      var tmpSeries = this.series.find(x => x.name === this.selectedSeries)
      if (tmpSeries) {
        for (let i = 0; i < this.teamRoster.length; i++) {
          const roster = this.teamRoster[i]
          if (roster.name === tmpSeries.team_roster) {
            this.rosterName = roster.name
            this.teams.push(...roster.players)
          }
        }
      }
      // this.teams.sort(function (a, b) {
      //   if (a.team_id > b.team_id) {
      //     return 1
      //   } else {
      //     if (a.player_id > b.player_id) {
      //       return 1
      //     } else return -1
      //   }
      // })

      this.playerColumns.splice(0)
      const keys = Object.keys(this.teams[0])
      // console.log(keys)
      for (const key of keys) {
        if (key.startsWith('_')) continue
        let col = {
          key: key,
          label: key,
          type: typeof this.teams[0][key]
        }
        if (key === 'Team Color') {
          col = {
            key: 'color',
            label: key
          }
        }
        this.playerColumns.push(col)
      }
    },
    resetModal () {
      this.teamIdState = null
      this.teamNameState = null
      this.teamTagstate = null
      this.playerIdState = null
      this.playerNameState = null
    },
    fetchDataFromVuex () {
      this.getPlayer(this.$store.state.selectedTeamRoster)
    },
    onInput (event, player, index) {
      player[index] = event.target.innerText
      //  this.teamsList[index].value = value;
    },
    getPlayer () {

      // this.teams.splice(0)

      // var tmpSeries = this.series.find(x => x.name === this.selectedSeries)
      // if (tmpSeries) {
      //   for (let i = 0; i < this.teamRoster.length; i++) {
      //     const roster = this.teamRoster[i]
      //     if (roster.name === tmpSeries.team_roster) {
      //       this.rosterName = roster.name
      //       this.teams.push(...roster.players)
      //     }
      //   }
      // }
      // this.teams.sort(function (a, b) {
      //   if (a.team_id > b.team_id) {
      //     return 1
      //   } else {
      //     if (a.player_id > b.player_id) {
      //       return 1
      //     } else return -1
      //   }
      // })
    },
    editPlayer (data) {
      this.player = JSON.parse(JSON.stringify(data))
      this.isEditMode = true
      this.playerIdState = 'Edit Player'

      this.$refs['player-edit-modal'].show()
    },
    addPlayer () {
      this.player = JSON.parse(JSON.stringify(emptyPlayer))
      this.isEditMode = false
      this.playerIdState = 'Add Player'

      this.$refs['player-edit-modal'].show()
    },
    removePlayer (data) {
      if (confirm('Delete Player?')) {
        const idx = this.teams.indexOf(data)
        this.teams.splice(idx, 1)
      }
    },
    savePlayer () {
      const idx = this.teams.indexOf(
        this.teams.find(x => x.player_id === parseInt(this.player.player_id))
      )
      if (this.isEditMode) {
        this.teams.splice(idx, 1, this.player)
        if (this.player.player_mapping === '') {
          this.player.player_mapping = null
        }
      } else {
        if (idx > -1) {
          this.$bvToast.toast(`${this.player.player_id} is already exist`, {
            title: 'Error',
            autoHideDelay: 5000
          })
          return
        }
        this.playerIdState = !!this.player.player_id
        this.playerNameState = !!this.player.player_name

        if (!this.playerIdState || !this.playerNameState) {
          return
        }
        this.teams.unshift(this.player)
      }

      this.$refs['player-edit-modal'].hide()
    },
    updateData: function () {
      if (!confirm('Update Data?')) return

      this.isLoading = true
      const url = `api/pubg/SetTeamRoster/${this.rosterName}`
      const param = {
        players: this.teams
      }
      axios
        .post(url, param)
        .then( () => {
          axios
            .get(`api/pubg/clearcache?name=team`)
            .then( () => {
              this.$store.dispatch('getTeamRoster')
            })
        })
        .catch(ex => {
          console.warn('ERROR!!!!! : ', ex)
        })
        .then(() => {
          this.isLoading = false
        })
    }

  }
}
</script>
<style scoped>
</style>
