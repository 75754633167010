<template>
  <div id="app" class="wrap">
    {{test}}
    <Layout>
      <router-view />
    </Layout>
    <!-- <div>
    </div>
      <div id="header" role="navigation" class="bd-navbar">
        <SidebarMenu1 style="overflow:auto; height:95%;" v-bind:menu="tourId" @selectTournament='selectTournamentId'/>
      </div>
      <div>
        <main class="content">
          <router-view/>
        </main>
    </div> -->
  </div>
</template>

<script>
//    <sidebar-menu :menu="menu" />
import Layout from './components/layout/layout.vue'
import axios from 'axios'

export default {
  name: 'App',
  components: {
    Layout
  },
  data () {
    return {
      session: 'Sign in',
      tourId: [],
      selectedSeries: '',
      selectedTournamentId: '',
      statType: 0,
      test: ''
    }
  },
  mounted () {
    if (process.env.NODE_ENV === 'development') {
      axios.defaults.baseURL = 'http://web.atum.live'
    }

    axios.defaults.headers.common['x-token'] = 'mbccni##pubg'
    axios.defaults.headers.get['Content-Type'] = 'application/json'

    this.$store.commit('toastErrorMsg', '')

    // if (this.$store.state.isSigned === true) {
    //   this.tourId = this.storetourId
    // }
    console.log('app refresh')
  },
  computed: {
    toastErrorMsg () {
      return this.$store.state.toastErrorMsg
    },
    toastInfoMsg () {
      return this.$store.state.toastInfoMsg
    }
  },
  watch: {
    toastErrorMsg (newVal) {
      if (newVal) {
        this.$bvToast.toast(newVal, {
          title: 'PUBG',
          variant: 'danger',
          autoHideDelay: 5000,
          toaster: 'b-toaster-bottom-right'
        })

        this.$store.commit('toastErrorMsg', '')
      }
    },
    toastInfoMsg (newVal) {
      if (newVal) {
        this.$bvToast.toast(newVal, {
          title: 'PUBG',
          autoHideDelay: 5000,
          toaster: 'b-toaster-bottom-right'
        })

        this.$store.commit('toastInfoMsg', '')
      }
    }
  },
  methods: {
    headerSignin: function (value) {
      this.tourId = value
      console.log(value)
      this.$store.commit('setTourId', value)
    },
    selectTournamentId: function (series, tournamentId, type) {
      this.selectedSeries = series
      this.selectedTournamentId = tournamentId
      this.statType = type
      // this.$router.go(
      //  this.$router.currentRoute,{params: {tournamentid: value, type: 0}
      // })
    }
  }
}
</script>

<style lang="scss">
@import './assets/scss/app.scss';
</style>

@import url(http://fonts.googleapis.com/earlyaccess/nanumgothic.css);

<style>
html body {
  font-family: nanumgothic !important;
  position: absolute;
  height: 100%;
  width: 100%;
  /* background-color: #d9e0e7; */
  /* background-color: #f1f1f1; */
}
</style>

<style scoped>
.wrap {
  height: 100%;
}
.content {
  margin-left: 190px;
  margin-top: 56px;
}
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.bd-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1071;
}
</style>
