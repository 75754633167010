<template>
  <b-table striped bordered hover head-variant="light"
    :items="sortedItems"
    :fields="columns"
    :tbody-tr-class="rowClass"
    responsive
  >
    <!-- <template v-slot:head(fields)="data">
    <span>
      {{ data.label }}111
      
      <b-icon
        v-if="isSortable(data.fields.key)"
        :icon="getSortIcon(data.fields.key)"
      />
    </span>
    </template> -->

    <template #head()="{label}">
      <span @click.stop.prevent="handleSortClick(label)">{{ label }}
        <br><b-icon v-if="isSortable(label)"
        :icon="getSortIcon(label)"/>
      </span>
    </template>
    <template #cell(index)="data">
      <span v-show="isKillLog">{{ data.index + 1 }}</span>
    </template>
    <template #cell(color)="data">
      {{data.item['Team Color']}}
      <div :style="{ backgroundColor: data.item['Team Color'], width: '20px', height: '20px', borderRadius: '4px' }"></div>
    </template>
      </b-table>
</template>

<script>
export default {
  name: 'MyTable',
  props: ['columns', 'tableDatas', 'isKillLog'],
  data () {
    return {
      // sortKeys: [],
      // sortDesc: [],
      sortByList: [], // 동적으로 저장할 컬럼 리스트
    }
  },
  computed: {
    // 다중 정렬된 아이템 목록
    sortedItems() {
      return [...this.tableDatas].sort((a, b) => this.multiSortCompare(a, b))
    }
  },
  methods: {
     // 해당 컬럼이 정렬 가능한지 확인
     isSortable(key) {
      if(this.columns){ 
        const field = this.columns.findIndex(f => f.key === key)
        if(field<0) return false
        return this.columns[field].customSort
      }
      return false
    },
    // 정렬 아이콘 결정
    getSortIcon(key) {
      const findIndex =this.sortByList.findIndex(entry => entry.columnKey === key)
      if ( findIndex < 0) {
        return 'dash'; // 정렬되지 않은 상태
      }
      return this.sortByList[findIndex].sortDesc? 'arrow-down' : 'arrow-up'; // 정렬 방향에 따른 아이콘
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.status === false) return 'table-secondary'
      if (item._back_color) {
        // return 'highlight-row'
        return 'table-success'
      }
    },
    multiSortCompare(a, b) {
      for (const { columnKey, sortDesc } of this.sortByList) {
        if (a[columnKey] < b[columnKey]) {
          return sortDesc ? 1 : -1;
        } else if (a[columnKey] > b[columnKey]) {
          return sortDesc ? -1 : 1;
        }
      }
      return 0; // 값이 같을 경우
    },
    handleSortClick(columnKey) {
      if(!this.isSortable(columnKey)) return;
      const existingEntry = this.sortByList.find(entry => entry.columnKey === columnKey);
      if (existingEntry) {
        if (!existingEntry.sortDesc) {
        // 첫 번째 클릭 후: 내림차순으로 전환
        existingEntry.sortDesc = true;
        } else {
          // 두 번째 클릭 후: 목록에서 제거 (세 번째 클릭)
          this.sortByList = this.sortByList.filter(entry => entry.columnKey !== columnKey);
        }
      } else {
        // 첫 번째 클릭 시: 오름차순으로 정렬
        this.sortByList.push({ columnKey, sortDesc: false });
      }
    },
    onSortChanged(sortKey, sortDesc) {
      console.log('test'+sortKey+sortDesc)
      // const index = this.sortByList.findIndex(entry => entry.sortBy === sortBy);
      // if (index === -1) {
      //   // 컬럼이 리스트에 없으면 추가
      //   // 오름차순
      //   sortDesc = false
      //   this.sortByList.push({ sortBy, sortDesc});
      // } else {
      //   // 이미 리스트에 있는 경우
      //   if(this.sortByList[index].sortDesc){
      //     // 세번째 클릭 삭제
      //     this.sortByList.splice(index, 1);
      //   }
      //   else {
      //     // 내림차순으로
      //     this.sortByList[index].sortDesc = true;
      //   }
      // }
    }
  }
}
</script>
<style>
.sr-only {
  display: none;
}
.table.b-table > thead > tr > th{
  background-image: none !important;
}

</style>

<style scoped>
/* table >>> tbody tr{
  height: 3rem;
}
table >>> tbody td{
  vertical-align: middle;
} */
.hexvalue1 {
background-color: #FFFFFF;
color: #FFFFFF;
}
 
.text-center {
  text-align: left !important;
  vertical-align: top !important;
}

/* table thead th {
  text-align: left !important;
  vertical-align: top !important;
} */


</style>